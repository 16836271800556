import React, { useEffect, useState } from 'react';
import FormControl from '@mui/material/FormControl';
import { CustomTextField } from '../../../shared/ui/components/Field/CustomTextField';
import { Hints } from '../../../shared/ui/components/Profile/Hints';
import ReactTooltip from 'react-tooltip';
import { StepButton } from '../../../shared/ui/components/StepButton';
import styled from 'styled-components';
import { colors, screenRessolutions } from '../../../shared/ui/constants/styleConstants';
import background1 from '../../../shared/utils/BackgroundProvider/assets/background1.png';
import background2 from '../../../shared/utils/BackgroundProvider/assets/background2.png';
import background3 from '../../../shared/utils/BackgroundProvider/assets/background3.png';
import background4 from '../../../shared/utils/BackgroundProvider/assets/background4.png';
import { useResize } from '../../../shared/utils/hooks/useResize';
import formatAmount from '../../../utils/formatAmount';
import PictureSlider from '../../../shared/ui/components/Booking/PictureSlider';
import { IconComponent } from '../../../shared/ui/components/Field/IconComponent';
import CustomDatePicker from '../../../shared/ui/components/CustomDatePicker';
import dayjs from 'dayjs';
import { ChipList } from '../../../shared/ui/components/Chip';
import neoGeo1 from '../../../shared/utils/BackgroundProvider/assets/neoGeo/neo-geo-1.jpg';
import neoGeo2 from '../../../shared/utils/BackgroundProvider/assets/neoGeo/neo-geo-2.jpg';
import neoGeo3 from '../../../shared/utils/BackgroundProvider/assets/neoGeo/neo-geo-3.jpg';
import neoGeo4 from '../../../shared/utils/BackgroundProvider/assets/neoGeo/neo-geo-4.jpg';
import neoGeo5 from '../../../shared/utils/BackgroundProvider/assets/neoGeo/neo-geo-5.jpg';
import neoGeo6 from '../../../shared/utils/BackgroundProvider/assets/neoGeo/neo-geo-6.jpg';
import park1 from '../../../shared/utils/BackgroundProvider/assets/parkKultury/park1.png';
import park2 from '../../../shared/utils/BackgroundProvider/assets/parkKultury/park2.png';
import park3 from '../../../shared/utils/BackgroundProvider/assets/parkKultury/park3.png';
import park4 from '../../../shared/utils/BackgroundProvider/assets/parkKultury/park4.png';
import fok1 from '../../../shared/utils/BackgroundProvider/assets/fok/fok1.png';
import fok2 from '../../../shared/utils/BackgroundProvider/assets/fok/fok2.png';
import fok3 from '../../../shared/utils/BackgroundProvider/assets/fok/fok3.png';
import tulskaya1 from '../../../shared/utils/BackgroundProvider/assets/tulskaya/tulskaya1.jpg';
import tulskaya2 from '../../../shared/utils/BackgroundProvider/assets/tulskaya/tulskaya2.jpg';
import tulskaya3 from '../../../shared/utils/BackgroundProvider/assets/tulskaya/tulskaya3.jpg';
import tulskaya4 from '../../../shared/utils/BackgroundProvider/assets/tulskaya/tulskaya4.jpg';
import tulskaya5 from '../../../shared/utils/BackgroundProvider/assets/tulskaya/tulskaya5.jpg';
import tulskaya6 from '../../../shared/utils/BackgroundProvider/assets/tulskaya/tulskaya6.jpg';
import tulskaya7 from '../../../shared/utils/BackgroundProvider/assets/tulskaya/tulskaya7.jpg';
import tulskaya8 from '../../../shared/utils/BackgroundProvider/assets/tulskaya/tulskaya8.jpg';
import tulskaya9 from '../../../shared/utils/BackgroundProvider/assets/tulskaya/tulskaya9.jpg';
import tulskaya10 from '../../../shared/utils/BackgroundProvider/assets/tulskaya/tulskaya10.jpg';
import MeetingRoomChip from '../../../entities/RoomReservation/MeetingRoomChip';
import MeetingRoomInfoCell from '../../../entities/RoomReservation/MeetingRoomInfoCell';
import HoursPickerTableHead from '../../../deprecated/components/HoursPicker2/HoursPickerTableHead';
import { TimeSlotsTitle } from '../../../entities/RoomReservation/MeetingRoomInfo';
import { AddressFieldBlock } from '../../../entities/Profile/PrefillProfile/model/PrefillProfileComponent';
import { useSelector } from 'react-redux';
import { accountSelector } from '../../../store/selectors/DefaultSelectors';

const MeetingRoomWrapper = styled.div`
  margin-top: 16px;
  display: flex;
  width: 100%;
  flex-direction: column;
  justify-content: space-between;
`;

const MeetingRoomTable = styled.div`
  display: flex;
  flex-direction: column;
  &:first-child {
    margin-bottom: 10px;
  }
`;

const MeetingRoomTimeRow = styled.div`
  display: flex;
  flex-direction: column;
  &:first-child {
    margin-bottom: 8px;
  }
`;
const MeetingRoomBookingBlock = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 16px;
`;

const PriceButtonWrapper = styled.div`
  display: flex;
  flex-direction: row;
`;

const MeetingRoomButtonWrapper = styled.div`
  width: ${props => (props.view === 'single' ? '100%' : '70%')};
`;

const MeetingRoomMainInfo = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

const MeetingRoomTitle = styled.p`
  font-size: 22px;
  font-weight: 500;
  margin: 0 0 14px 0;
  color: ${colors.workkiDarkMain};
`;

const MeetingRoomSliderWrapper = styled.div`
  display: flex;
  flex-direction: column;
  @media (min-width: ${screenRessolutions.smallMobile}px) {
    width: 100%;
  }
`;
const MeetingRoomDatePickerWrapper = styled.div`
  display: flex;
  flex-direction: column;
  @media (min-width: ${screenRessolutions.smallMobile}px) {
    width: 100%;
    margin-top: 16px;
    margin-bottom: 8px;
  }
`;

const MeetingRoomPaymentInfo = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

const TimeWrapper = styled.div`
  display: flex;
  flex-direction: row;
`;

const TimeBlock = styled.div`
  display: flex;
  min-width: 150px;
  max-width: 50%;
  height: 48px;
  margin: 0 6px;
`;
const MeetingRoomTimelineTitle = styled.p`
  font-size: 16px;
  margin: 0 0 8px 0;
`;

const MeetingRoomPriceInfo = styled.div`
  display: flex;
  flex-direction: column;
  margin-right: 12px;
  width: 30%;
  & > p:first-of-type {
    font-weight: 300;
    color: ${colors.workkiDarkMiddle};
    font-size: 14px;
  }
  & > p {
    margin: 0;
    color: ${colors.workkiDarkFill};
  }
`;
const TimeSlotsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
`;

export const MeetingRoomWidget = ({ handleClose, handleSubmit, dialogData }) => {
  const {
    markers,
    changeMarker,
    resetSelectedAll,
    setSelectedReservation,
    setOverflowStatus,
    dateTimeObjects,
    location,
    handleSlideChange,
    dateTime,
    isSelected,
    getCurrentPrice,
    getError,
    reserveRoom,
    rooms,
    roomName,
    dateFrom,
    pickedRoom,
    roomId,
    key,
    pickedDate,
    locationFreeHours,
    setPickedDate
  } = dialogData;
  const [localImages, setLocalImages] = useState([background1, background2, background3, background4]);
  const [activeSlide, setActiveSlide] = useState(0);
  const account = useSelector(accountSelector);
  const [isMouseDown, setIsMouseDown] = useState(false);
  const [openDialog, setOpenDialog] = useState({
    booking: false,
    success: false,
    error: false
  });
  const [notEnoughMoney, setNotEnoughMoney] = useState(false);
  const [roomsData, setRoomsData] = useState([]);
  const [formData, setFormData] = useState({
    timeStart: '',
    timeEnd: ''
  });
  const [filled, setFilled] = useState({
    timeStart: false,
    timeEnd: false
  });
  const [isFocused, setIsFocused] = useState({
    timeStart: false,
    timeEnd: false
  });
  useEffect(() => {
    if (dateTime && dateTime.from && dateTime.to) {
      setFormData({
        timeStart: dateTime.from.format('HH:mm').toString(),
        timeEnd: dateTime.to.format('HH:mm').toString()
      });
    }
  }, [dateTime]);
  const [activeSlider, setActiveSlider] = useState(null);

  const changeIsMouseDown = isDown => {
    setIsMouseDown(isDown);
  };
  //TODO - очень неэффективно - поправить
  const [isLaptop, setIsLaptop] = useState(window.innerWidth >= screenRessolutions.laptop);
  const [isDesktop, setIsDesktop] = useState(window.innerWidth >= screenRessolutions.laptop);
  const handleChangeScreenState = () => {
    const isScreenLaptop = window.innerWidth >= screenRessolutions.laptop;
    const isScreenDesktop = window.innerWidth >= screenRessolutions.desktop;
    if (isScreenLaptop !== isLaptop) setIsLaptop(isScreenLaptop);
    if (isScreenDesktop !== isDesktop) setIsDesktop(isScreenDesktop);
  };
  useResize(handleChangeScreenState, 0);

  const isCorrectedSelected = () => {
    const allSelectedIndexMarkers = markers.allIds
      .map((id, index) => (markers.byId[id].status === 'current' ? index : null))
      .filter(item => item !== null);
    if (allSelectedIndexMarkers.length === 0) {
      return true;
    }
    const first = allSelectedIndexMarkers[0];
    const last = allSelectedIndexMarkers[allSelectedIndexMarkers.length - 1];
    const allIndexMarkersOnInterval = markers.allIds
      .map((id, index) => (first <= index && index <= last ? index : null))
      .filter(item => item !== null);
    return allSelectedIndexMarkers.length === allIndexMarkersOnInterval.length;
  };

  const renderPrice = () => {
    const price = formatAmount(getCurrentPrice());
    return price;
  };

  const renderButton = () => {
    if (getError() === 'service_consume_schedule_time_is_unavailable') {
      return '';
    }

    const price = getCurrentPrice();

    if (price === null) {
      return <span className='room-reservation__book-link'>Цена не установлена</span>;
    }

    return renderPrice();
  };
  const renderAdditionalCost = () => {
    if (Number(getCurrentPrice()) > account.amount) {
      return `${getCurrentPrice() - account.amount}`;
    }
    return null;
  };

  const handleInputChange = evt => {
    const { name, value } = evt.target;
    updateFilledState(name, value);
    updateFormData(name, value);
  };

  const updateFilledState = (name, inputValue) => {
    setFilled(prevState => ({
      ...prevState,
      [name]: inputValue !== ''
    }));
  };

  const handleFocus = fieldName => {
    setIsFocused(prevState => ({
      ...prevState,
      [fieldName]: true
    }));
  };

  const handleBlur = fieldName => {
    setIsFocused(prevState => ({
      ...prevState,
      [fieldName]: false
    }));
  };

  const updateFormData = (name, inputValue) => {
    setFormData(prevState => ({
      ...prevState,
      [name]: inputValue
    }));
  };

  const handleEraseValue = fieldName => {
    setFormData(prevState => ({
      ...prevState,
      [fieldName]: ''
    }));
  };

  const isActiveTimeLine = markers => {
    return markers.allIds.some(id => markers.byId[id].status === 'current');
  };
  useEffect(() => {
    const data = [];
    for (const key in rooms.byId) {
      if (rooms.byId.hasOwnProperty(key)) {
        const child = rooms.byId[key];
        data.push(child);
      }
    }
    setRoomsData(data);
    if (location) {
      switch (location.id) {
        case 2:
          if (roomName === 'M01') {
            setLocalImages([neoGeo1, neoGeo2]);
          } else if (roomName === 'M02') {
            setLocalImages([neoGeo3, neoGeo4]);
          } else {
            setLocalImages([neoGeo1, neoGeo2, neoGeo3, neoGeo4, neoGeo5, neoGeo6]);
          }
          break;
        case 4:
          if (roomName === 'М403') {
            setLocalImages([park1, park2]);
          } else if (roomName === 'М404') {
            setLocalImages([park3, park4]);
          } else {
            setLocalImages([park1, park2, park3, park4]);
          }
          break;
        case 5:
          if (roomName === 'Конференц-зал') {
            setLocalImages([fok1, fok2, fok3]);
          } else {
            setLocalImages([
              tulskaya1,
              tulskaya2,
              tulskaya3,
              tulskaya4,
              tulskaya5,
              tulskaya6,
              tulskaya7,
              tulskaya8,
              tulskaya9,
              tulskaya10
            ]);
          }
          break;
        case 7:
          if (roomName === 'M651') {
            setLocalImages([tulskaya2, tulskaya4]);
          } else {
            setLocalImages([
              tulskaya1,
              tulskaya2,
              tulskaya3,
              tulskaya4,
              tulskaya5,
              tulskaya6,
              tulskaya7,
              tulskaya8,
              tulskaya9,
              tulskaya10
            ]);
          }
          break;
        default:
          setLocalImages([background1, background2, background3, background4]);
      }
    }
  }, []);
  const handleMouseEnter = sliderId => {
    setActiveSlider(sliderId);
  };

  const handleMouseLeave = () => {
    setActiveSlider(null);
  };
  return (
    <MeetingRoomWrapper>
      <MeetingRoomSliderWrapper onMouseEnter={() => handleMouseEnter(roomId)} onMouseLeave={handleMouseLeave}>
        <MeetingRoomMainInfo>
          <MeetingRoomTitle>
            {roomName.includes('Ивент') || roomName.includes('Конференц-зал') ? roomName : 'Переговорная ' + roomName}
          </MeetingRoomTitle>
          <IconComponent
            type='close'
            spacing={'0px'}
            handleAction={handleClose}
            defaultColor={'#24272A'}
            hoverColor={'#383c40'}
          />
        </MeetingRoomMainInfo>
        <PictureSlider
          key={key}
          images={localImages}
          useManualControls={false}
          handleSlideChange={handleSlideChange}
          galleryIndex={activeSlide}
        />
      </MeetingRoomSliderWrapper>
      <MeetingRoomDatePickerWrapper>
        <FormControl fullWidth={true}>
          <CustomDatePicker
            customLabel={'День бронирования'}
            pickedDate={dayjs(pickedDate)}
            setPickedDate={setPickedDate}
          />
        </FormControl>
      </MeetingRoomDatePickerWrapper>
      <TimeSlotsWrapper>
        <TimeSlotsTitle>Выберите слот на час</TimeSlotsTitle>
        <ChipList type={'time'}>
          {markers.allIds.slice(0, 12).map(id => (
            <MeetingRoomChip
              key={id}
              dateTimeObjects={dateTimeObjects.byId}
              marker={markers.byId[id]}
              timeMarker={dateTimeObjects.byId[id]}
              changeMarker={changeMarker}
              resetSelectedAll={resetSelectedAll}
              changeIsMouseDown={changeIsMouseDown}
              isMouseDown={isMouseDown}
              setOverflowStatus={setOverflowStatus}
              isCorrectedSelected={isCorrectedSelected}
              setSelectedReservation={setSelectedReservation}
            />
          ))}
        </ChipList>
      </TimeSlotsWrapper>
      <MeetingRoomTable>
        <TimeSlotsTitle>или укажите период для брони</TimeSlotsTitle>
        {markers && (
          <MeetingRoomTimeRow>
            <table style={{ marginLeft: '-20px' }}>
              {dateFrom && (
                <thead className='hours-picker__table-head'>
                  <tr className='hours-picker__row'>
                    {dateTimeObjects.allIds.slice(0, 7).map(id => {
                      return (
                        <HoursPickerTableHead
                          type={'mobile'}
                          key={id}
                          date={dateFrom.date()}
                          timeMarker={dateTimeObjects.byId[id]}
                        />
                      );
                    })}
                  </tr>
                </thead>
              )}
              <tr>
                <td style={{ width: '10px' }} />
                {markers.allIds.slice(0, 6).map(id => (
                  <MeetingRoomInfoCell
                    type={'mobile'}
                    key={id}
                    marker={markers.byId[id]}
                    markerList={markers.byId}
                    changeMarker={changeMarker}
                    resetSelectedAll={resetSelectedAll}
                    changeIsMouseDown={changeIsMouseDown}
                    isMouseDown={isMouseDown}
                    setOverflowStatus={setOverflowStatus}
                    isCorrectedSelected={isCorrectedSelected}
                    setSelectedReservation={setSelectedReservation}
                  />
                ))}
              </tr>
            </table>
            <table style={{ marginLeft: '-20px' }}>
              <tr className='hours-picker__row'>
                <td style={{ width: '10px' }} />
                {markers.allIds.slice(6, 12).map(id => (
                  <MeetingRoomInfoCell
                    type={'mobile'}
                    key={id}
                    marker={markers.byId[id]}
                    markerList={markers.byId}
                    changeMarker={changeMarker}
                    resetSelectedAll={resetSelectedAll}
                    changeIsMouseDown={changeIsMouseDown}
                    isMouseDown={isMouseDown}
                    setOverflowStatus={setOverflowStatus}
                    isCorrectedSelected={isCorrectedSelected}
                    setSelectedReservation={setSelectedReservation}
                  />
                ))}
              </tr>
              {dateFrom && (
                <thead className='hours-picker__table-head'>
                  <tr className='hours-picker__row'>
                    {dateTimeObjects.allIds.slice(6, 13).map(id => {
                      return (
                        <HoursPickerTableHead
                          type={'mobile'}
                          key={id}
                          date={dateFrom.date()}
                          timeMarker={dateTimeObjects.byId[id]}
                        />
                      );
                    })}
                  </tr>
                </thead>
              )}
            </table>
          </MeetingRoomTimeRow>
        )}
        {isSelected && (
          <MeetingRoomBookingBlock>
            <div
              className={`room-reservation__reservation-result ${
                isSelected() ? '' : 'room-reservation__reservation-result_empty'
              }`}
            >
              {isSelected() && isActiveTimeLine(markers) ? (
                <MeetingRoomPaymentInfo>
                  <TimeWrapper>
                    <TimeBlock>
                      <FormControl fullWidth={true}>
                        <AddressFieldBlock>
                          <CustomTextField
                            error={''}
                            type='text'
                            disabled={true}
                            name='timeStart'
                            id='outlined-helperText'
                            label='с'
                            variant='filled'
                            value={formData.timeStart}
                            onFocus={() => handleFocus('timeStart')}
                            onBlur={() => handleBlur('timeStart')}
                            onChange={handleInputChange}
                            helperText={''}
                            endAdornmentProps={{
                              filled: filled.timeStart,
                              isFocused: isFocused.timeStart,
                              handleEraseValue: name => handleEraseValue(name)
                            }}
                            autoFocus={false}
                          />
                        </AddressFieldBlock>
                      </FormControl>
                    </TimeBlock>
                    <p>—</p>
                    <TimeBlock>
                      <FormControl fullWidth={true}>
                        <AddressFieldBlock>
                          <CustomTextField
                            error={''}
                            disabled={true}
                            type='text'
                            name='timeEnd'
                            id='outlined-helperText'
                            label='до'
                            variant='filled'
                            value={formData.timeEnd}
                            onFocus={() => handleFocus('timeEnd')}
                            onBlur={() => handleBlur('timeEnd')}
                            onChange={handleInputChange}
                            helperText={''}
                            endAdornmentProps={{
                              filled: filled.timeEnd,
                              isFocused: isFocused.timeEnd,
                              handleEraseValue: name => handleEraseValue(name)
                            }}
                            autoFocus={false}
                          />
                        </AddressFieldBlock>
                      </FormControl>
                    </TimeBlock>
                  </TimeWrapper>
                  <p className='room-reservation__book-info'>
                    <Hints
                      hints={['Вы можете выбрать не более 12 часов']}
                      hintText='Для того чтобы выбрать временной интервал - выделите необходимое время.'
                    />
                  </p>
                  <ReactTooltip />
                </MeetingRoomPaymentInfo>
              ) : (
                <p className='room-reservation__book-info'>
                  <Hints
                    hints={['Вы можете выбрать не более 12 часов']}
                    hintText='Для того чтобы выбрать временной интервал - выделите необходимое время.'
                  />
                </p>
              )}
            </div>
          </MeetingRoomBookingBlock>
        )}
        <PriceButtonWrapper>
          {isSelected() && isActiveTimeLine(markers) && (
            <>
              <MeetingRoomPriceInfo>
                <p>К оплате</p>
                <p>{renderButton()}₽</p>
              </MeetingRoomPriceInfo>
              {renderAdditionalCost() && locationFreeHours === 0 && (
                <MeetingRoomPriceInfo>
                  <p>Нужно внести</p>
                  <p>{renderAdditionalCost()}₽</p>
                </MeetingRoomPriceInfo>
              )}
            </>
          )}
          {roomsData.map(
            room =>
              room.service.name === roomName && (
                <MeetingRoomButtonWrapper view={!isSelected() ? 'single' : 'multiple'}>
                  <FormControl fullWidth={true}>
                    <StepButton
                      disabled={!isSelected() || !isActiveTimeLine(markers) || room.service.id !== pickedRoom.id}
                      handleAction={event => {
                        if (isSelected() && renderAdditionalCost() && locationFreeHours === 0) {
                          window.location.href = '/payments';
                        } else {
                          reserveRoom(event);
                          handleSubmit();
                        }
                      }}
                      type={'filled'}
                      iconColor={'white'}
                      filledType={'red'}
                      title={
                        isSelected() && renderAdditionalCost() && locationFreeHours === 0 ? 'Оплатить' : 'Забронировать'
                      }
                      spacing={'0'}
                      iconType={'arrow'}
                    />
                  </FormControl>
                </MeetingRoomButtonWrapper>
              )
          )}
        </PriceButtonWrapper>
      </MeetingRoomTable>
    </MeetingRoomWrapper>
  );
};
