import React, { Component } from 'react';
import PropTypes from 'prop-types';

class ProfileAvatar extends Component {
  render() {
    const { src, alt, sendCustomerFile, openEditorCallback } = this.props;

    if (src === null) {
      return (
        <div className='profile-avatar'>
          <button onClick={this.openModalWindow} type='button' className='profile-avatar__empty btn'>
            загрузите логотип
          </button>
        </div>
      );
    }

    return (
      <div className='profile-avatar'>
        {!!src && (
          <div onClick={openEditorCallback} className='profile-avatar__action'>
            <button type='button'>
              <svg width='14' height='15' viewBox='0 0 14 15' fill='none' xmlns='http://www.w3.org/2000/svg'>
                <path
                  fillRule='evenodd'
                  clipRule='evenodd'
                  d='M8.875 3.125C7.49429 3.125 6.375 4.24429 6.375 5.625C6.375 7.00571 7.49429 8.125 8.875 8.125C10.2557 8.125 11.375 7.00571 11.375 5.625C11.375 4.24429 10.2557 3.125 8.875 3.125ZM7.625 5.625C7.625 4.93464 8.18464 4.375 8.875 4.375C9.56536 4.375 10.125 4.93464 10.125 5.625C10.125 6.31536 9.56536 6.875 8.875 6.875C8.18464 6.875 7.625 6.31536 7.625 5.625Z'
                  fill='#383C40'
                />
                <path
                  fillRule='evenodd'
                  clipRule='evenodd'
                  d='M0.125 2.5C0.125 1.46447 0.964466 0.625 2 0.625H12C13.0355 0.625 13.875 1.46447 13.875 2.5V12.5C13.875 13.5355 13.0355 14.375 12 14.375H2C0.964467 14.375 0.125 13.5355 0.125 12.5V2.5ZM1.375 12.5V10.2588L3.43306 8.20079C3.67714 7.95671 4.07286 7.95671 4.31694 8.20079L9.24115 13.125H2C1.65482 13.125 1.375 12.8452 1.375 12.5ZM12 13.125H11.0089L5.20083 7.31691C4.46859 6.58467 3.28141 6.58467 2.54918 7.31691L1.375 8.49108V2.5C1.375 2.15482 1.65482 1.875 2 1.875H12C12.3452 1.875 12.625 2.15482 12.625 2.5V12.5C12.625 12.8452 12.3452 13.125 12 13.125Z'
                  fill='#383C40'
                />
              </svg>
            </button>
            <span>Изменить</span>
          </div>
        )}
        <img className='profile-avatar__img' src={src} alt={alt} />
        {!!src && (
          <div
            onClick={() => {
              alert('Удаление...');
            }}
            className='profile-avatar__action'
          >
            <button type='button'>
              <svg width='14' height='15' viewBox='0 0 14 15' fill='none' xmlns='http://www.w3.org/2000/svg'>
                <path
                  fillRule='evenodd'
                  clipRule='evenodd'
                  d='M3.87524 2.5V3.75H0.750244C0.405066 3.75 0.125244 4.02982 0.125244 4.375C0.125244 4.72018 0.405066 5 0.750244 5H1.45311L2.48616 12.7479C2.61036 13.6794 3.40496 14.3751 4.34471 14.3751H9.65592C10.5957 14.3751 11.3903 13.6794 11.5145 12.7479L12.5475 5H13.2502C13.5954 5 13.8752 4.72018 13.8752 4.375C13.8752 4.02982 13.5954 3.75 13.2502 3.75H10.1252V2.5C10.1252 1.46447 9.28578 0.625 8.25025 0.625H5.75024C4.71471 0.625 3.87524 1.46447 3.87524 2.5ZM5.75024 1.875C5.40507 1.875 5.12524 2.15482 5.12524 2.5V3.75H8.87525V2.5C8.87525 2.15482 8.59542 1.875 8.25025 1.875H5.75024ZM11.2865 5H2.71417L3.72519 12.5827C3.7666 12.8932 4.03146 13.1251 4.34471 13.1251H9.65592C9.96917 13.1251 10.234 12.8932 10.2754 12.5827L11.2865 5Z'
                  fill='#383C40'
                />
              </svg>
            </button>
            <span>Удалить</span>
          </div>
        )}

        {/*<ProfileAvatarModal*/}
        {/*  modalWindowTitle='Добавление изображения'*/}
        {/*  sendCustomerFile={sendCustomerFile}*/}
        {/*  isModalWindowOpen={isModalWindowOpen}*/}
        {/*  closeModalWindow={this.closeModalWindow}*/}
        {/*/>*/}
      </div>
    );
  }
}

ProfileAvatar.propTypes = {
  src: PropTypes.string,
  alt: PropTypes.string,
  sendCustomerFile: PropTypes.func
};

export default ProfileAvatar;
