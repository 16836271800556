import React, { useState, useEffect } from 'react';
import ReactTooltip from 'react-tooltip';
import PropTypes from 'prop-types';
import MeetingRoomInfoCell from './MeetingRoomInfoCell';
import PictureSlider from '../../shared/ui/components/Booking/PictureSlider';
import styled from 'styled-components';
import { screenRessolutions, colors } from '../../shared/ui/constants/styleConstants';
import CustomList from '../../shared/ui/components/Booking/CustomList';
import { StepButton } from '../../shared/ui/components/StepButton';
import formatAmount from '../../utils/formatAmount';
import FormControl from '@mui/material/FormControl';
import { CustomTextField } from '../../shared/ui/components/Field/CustomTextField';
import { Hints } from '../../shared/ui/components/Profile/Hints';
import { useResize } from '../../shared/utils/hooks/useResize';
import background1 from '../../shared/utils/BackgroundProvider/assets/background1.png';
import background2 from '../../shared/utils/BackgroundProvider/assets/background2.png';
import background3 from '../../shared/utils/BackgroundProvider/assets/background3.png';
import background4 from '../../shared/utils/BackgroundProvider/assets/background4.png';
import neoGeo1 from '../../shared/utils/BackgroundProvider/assets/neoGeo/neo-geo-1.jpg';
import park1 from '../../shared/utils/BackgroundProvider/assets/parkKultury/park1.png';
import park2 from '../../shared/utils/BackgroundProvider/assets/parkKultury/park2.png';
import park3 from '../../shared/utils/BackgroundProvider/assets/parkKultury/park3.png';
import park4 from '../../shared/utils/BackgroundProvider/assets/parkKultury/park4.png';
import neoGeo2 from '../../shared/utils/BackgroundProvider/assets/neoGeo/neo-geo-2.jpg';
import neoGeo3 from '../../shared/utils/BackgroundProvider/assets/neoGeo/neo-geo-3.jpg';
import neoGeo4 from '../../shared/utils/BackgroundProvider/assets/neoGeo/neo-geo-4.jpg';
import neoGeo5 from '../../shared/utils/BackgroundProvider/assets/neoGeo/neo-geo-5.jpg';
import neoGeo6 from '../../shared/utils/BackgroundProvider/assets/neoGeo/neo-geo-6.jpg';
import fok1 from '../../shared/utils/BackgroundProvider/assets/fok/fok1.png';
import fok2 from '../../shared/utils/BackgroundProvider/assets/fok/fok2.png';
import fok3 from '../../shared/utils/BackgroundProvider/assets/fok/fok3.png';
import tulskaya1 from '../../shared/utils/BackgroundProvider/assets/tulskaya/tulskaya1.jpg';
import tulskaya2 from '../../shared/utils/BackgroundProvider/assets/tulskaya/tulskaya2.jpg';
import tulskaya3 from '../../shared/utils/BackgroundProvider/assets/tulskaya/tulskaya3.jpg';
import tulskaya4 from '../../shared/utils/BackgroundProvider/assets/tulskaya/tulskaya4.jpg';
import tulskaya5 from '../../shared/utils/BackgroundProvider/assets/tulskaya/tulskaya5.jpg';
import tulskaya6 from '../../shared/utils/BackgroundProvider/assets/tulskaya/tulskaya6.jpg';
import tulskaya7 from '../../shared/utils/BackgroundProvider/assets/tulskaya/tulskaya7.jpg';
import tulskaya8 from '../../shared/utils/BackgroundProvider/assets/tulskaya/tulskaya8.jpg';
import tulskaya9 from '../../shared/utils/BackgroundProvider/assets/tulskaya/tulskaya9.jpg';
import tulskaya10 from '../../shared/utils/BackgroundProvider/assets/tulskaya/tulskaya10.jpg';
import { MeetingRoomDialog } from '../../widgets/BookingWidgets/MeetingRooms/MeetingRoomDialog';
import moment from 'moment-timezone';
import MeetingRoomChip from './MeetingRoomChip';
import { ChipList } from '../../shared/ui/components/Chip';
import HoursPickerTableHead from '../../deprecated/components/HoursPicker2/HoursPickerTableHead';
import { AddressFieldBlock } from '../Profile/PrefillProfile/model/PrefillProfileComponent';
import { useSelector } from 'react-redux';
import { accountSelector, customerSelector } from '../../store/selectors/DefaultSelectors';

const MeetingRoomWrapper = styled.div`
  display: grid;
  @media (min-width: ${screenRessolutions.smallMobile}px) {
    width: 100%;
    flex-direction: column;
    grid-template-areas:
      'slider'
      'info';
    margin-bottom: 36px;
  }
  @media (min-width: ${screenRessolutions.tablet}px) {
    flex-direction: column;
    grid-template-areas: 'slider info';
    gap: 24px;
    grid-template-columns: 1fr 1fr;
  }
  @media (min-width: ${screenRessolutions.laptop}px) {
    flex-direction: column;
    grid-template-areas:
      'slider info'
      'slider booking';
    gap: 32px;
    grid-template-columns: 320px 1fr;
    justify-content: center;
    height: fit-content;
  }
  @media (min-width: ${screenRessolutions.desktop}px) {
    flex-direction: column;
    grid-template-areas: 'slider info booking';
    gap: 24px;
    grid-template-columns: 0.25fr 0.25fr 1fr;
    justify-content: flex-start;
  }
`;

const MeetingRoomTable = styled.div`
  display: flex;
  flex-direction: column;
  grid-area: booking;
`;

const MeetingRoomTimeRow = styled.div`
  display: flex;
  flex-direction: column;
`;

const MeetingRoomBookingBlock = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
`;

const PriceButtonWrapper = styled.div`
  display: flex;
  flex-direction: row;
  margin-right: 30px;
`;

const MeetingRoomButtonWrapper = styled.div`
  @media (min-width: ${screenRessolutions.smallMobile}px) {
    display: none;
  }
  @media (min-width: ${screenRessolutions.laptop}px) {
    display: flex;
    margin-left: 16px;
    width: 189px;
  }
`;
const TariffButtonWrapper = styled.div`
  @media (min-width: ${screenRessolutions.smallMobile}px) {
    margin-top: 12px;
    display: flex;
    width: 100%;
  }
  @media (min-width: ${screenRessolutions.tablet}px) {
    margin-bottom: 10px;
  }
  @media (min-width: ${screenRessolutions.laptop}px) {
    display: none;
  }
`;
const InfoWrapper = styled.div`
  grid-area: info;
  display: flex;
  flex-direction: column;
  @media (min-width: ${screenRessolutions.smallMobile}px) {
    justify-content: flex-start;
  }
  @media (min-width: ${screenRessolutions.tablet}px) {
    justify-content: space-between;
  }
`;

const MeetingRoomMainInfo = styled.div`
  display: flex;
  flex-direction: column;
`;
const MeetingRoomTariffInfo = styled.div`
  display: flex;
  flex-direction: column;
`;

const MeetingRoomTitle = styled.p`
  @media (min-width: ${screenRessolutions.smallMobile}px) {
    font-size: 18px;
  }
  @media (min-width: ${screenRessolutions.tablet}px) {
    font-size: 22px;
  }
  font-weight: 500;
  margin: 0;
  color: ${colors.workkiDarkMain};
`;
export const MeetingTariffTitle = styled.p`
  font-size: 14px;
  font-weight: 500;
  margin: 0;
  color: ${colors.workkiDarkMain};
`;
const TimeSlotsWrapper = styled.div`
  @media (min-width: ${screenRessolutions.smallMobile}px) {
    display: none;
  }
  @media (min-width: ${screenRessolutions.laptop}px) {
    display: flex;
    flex-direction: column;
  }
  margin-bottom: 24px;
`;
export const TimeSlotsTitle = styled.p`
  margin-bottom: 4px;
  margin-top: 0;
  font-size: 14px;
`;
const MeetingRoomSubtitleWrapper = styled.div`
  display: flex;
  flex-direction: row;
  :first-child {
    margin-right: 30px;
  }
`;

const MeetingRoomSubtitle = styled.p`
  font-size: 12px;
  font-weight: 300;
  margin: 0;
  color: ${colors.workkiDarkSecondary};
`;

const MeetingRoomSliderWrapper = styled.div`
  display: flex;
  flex-direction: column;
  grid-area: slider;
  @media (min-width: ${screenRessolutions.smallMobile}px) {
    width: 100%;
    margin-bottom: 12px;
  }
  @media (min-width: ${screenRessolutions.desktop}px) {
    width: 320px;
  }
`;

const MeetingRoomPaymentInfo = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

const DateBlock = styled.div`
  display: flex;
  flex-direction: column;
  margin-right: 20px;
  & > p:first-of-type {
    color: ${colors.workkiDarkFill};
  }
  & > p {
    margin: 0;
    color: ${colors.workkiDarkMiddle};
    font-size: 14px;
    font-weight: 300;
  }
`;

const DateTimeWrapper = styled.div`
  display: flex;
  flex-direction: row;
`;

const TimeWrapper = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
`;

const TimeBlock = styled.div`
  display: flex;
  max-width: 88px;
  height: 48px;
  margin: 0 6px;
`;

const MeetingRoomPriceInfo = styled.div`
  display: flex;
  flex-direction: column;
  margin-right: ${props => props.spacing};
  & > p:first-of-type {
    font-weight: 300;
    color: ${colors.workkiDarkMiddle};
    font-size: 14px;
  }
  & > p {
    margin: 0;
    color: ${colors.workkiDarkFill};
    font-size: 16px;
  }
`;

const MeetingRoomInfo = props => {
  const {
    description,
    markers,
    changeMarker,
    resetSelectedAll,
    setSelectedReservation,
    setOverflowStatus,
    dateTimeObjects,
    location,
    handleSlideChange,
    dateTime,
    isSelected,
    getCurrentPrice,
    getError,
    reserveRoom,
    rooms,
    roomName,
    dateFrom,
    pickedRoom,
    roomId,
    locationFreeHours,
    key
  } = props;
  const [localImages, setLocalImages] = useState([]);
  const customer = useSelector(customerSelector);
  const account = useSelector(accountSelector);
  const [activeChip, setActiveChip] = useState(null);
  const [timeSlots, setTimeSlots] = useState([
    {
      key: 0,
      label: 'с 9 до 10',
      date: moment()
        .set('hours', 9)
        .set('minutes', 0)
        .set('seconds', 0),
      checked: false
    },
    {
      key: 1,
      label: 'с 10 до 11',
      date: moment()
        .set('hours', 10)
        .set('minutes', 0)
        .set('seconds', 0),
      checked: false
    },
    {
      key: 2,
      label: 'с 11 до 12',
      date: moment()
        .set('hours', 11)
        .set('minutes', 0)
        .set('seconds', 0),
      checked: false
    },
    {
      key: 3,
      label: 'с 12 до 13',
      date: moment()
        .set('hours', 12)
        .set('minutes', 0)
        .set('seconds', 0),
      checked: false
    },
    {
      key: 4,
      label: 'с 13 до 14',
      date: moment()
        .set('hours', 13)
        .set('minutes', 0)
        .set('seconds', 0),
      checked: false
    },
    {
      key: 5,
      label: 'с 14 до 15',
      date: moment()
        .set('hours', 14)
        .set('minutes', 0)
        .set('seconds', 0),
      checked: false
    },
    {
      key: 6,
      label: 'с 15 до 16',
      date: moment()
        .set('hours', 15)
        .set('minutes', 0)
        .set('seconds', 0),
      checked: false
    },
    {
      key: 7,
      label: 'с 16 до 17',
      date: moment()
        .set('hours', 16)
        .set('minutes', 0)
        .set('seconds', 0),
      checked: false
    },
    {
      key: 8,
      label: 'с 17 до 18',
      date: moment()
        .set('hours', 17)
        .set('minutes', 0)
        .set('seconds', 0),
      checked: false
    },
    {
      key: 9,
      label: 'с 18 до 19',
      date: moment()
        .set('hours', 18)
        .set('minutes', 0)
        .set('seconds', 0),
      checked: false
    },
    {
      key: 10,
      label: 'с 19 до 20',
      date: moment()
        .set('hours', 19)
        .set('minutes', 0)
        .set('seconds', 0),
      checked: false
    },
    {
      key: 11,
      label: 'с 20 до 21',
      date: moment()
        .set('hours', 20)
        .set('minutes', 0)
        .set('seconds', 0),
      checked: false
    }
  ]);
  const [activeSlide, setActiveSlide] = useState(0);
  const [isMouseDown, setIsMouseDown] = useState(false);
  const [openDialog, setOpenDialog] = useState({
    booking: false,
    success: false,
    error: false
  });
  const [notEnoughMoney, setNotEnoughMoney] = useState(false);
  const [roomsData, setRoomsData] = useState([]);
  const [formData, setFormData] = useState({
    timeStart: '',
    timeEnd: ''
  });
  const [filled, setFilled] = useState({
    timeStart: false,
    timeEnd: false
  });
  const [isFocused, setIsFocused] = useState({
    timeStart: false,
    timeEnd: false
  });
  useEffect(() => {
    if (dateTime.from && dateTime.to) {
      setFormData({
        timeStart: dateTime.from.format('HH:mm').toString(),
        timeEnd: dateTime.to.format('HH:mm').toString()
      });
    }
  }, [dateTime]);
  const [activeSlider, setActiveSlider] = useState(null);

  const changeIsMouseDown = isDown => {
    setIsMouseDown(isDown);
  };
  //TODO - очень неэффективно - поправить
  const [isTablet, setIsTablet] = useState(window.innerWidth >= screenRessolutions.smallMobile);
  const [isLaptop, setIsLaptop] = useState(window.innerWidth >= screenRessolutions.laptop);
  const [isDesktop, setIsDesktop] = useState(window.innerWidth >= screenRessolutions.laptop);
  const handleChangeScreenState = () => {
    const isScreenLaptop = window.innerWidth >= screenRessolutions.laptop;
    const isScreenDesktop = window.innerWidth >= screenRessolutions.desktop;
    if (isScreenLaptop !== isLaptop) setIsLaptop(isScreenLaptop);
    if (isScreenDesktop !== isDesktop) setIsDesktop(isScreenDesktop);
  };
  useResize(handleChangeScreenState, 0);

  const isCorrectedSelected = () => {
    const allSelectedIndexMarkers = markers.allIds
      .map((id, index) => (markers.byId[id].status === 'current' ? index : null))
      .filter(item => item !== null);
    if (allSelectedIndexMarkers.length === 0) {
      return true;
    }
    const first = allSelectedIndexMarkers[0];
    const last = allSelectedIndexMarkers[allSelectedIndexMarkers.length - 1];
    const allIndexMarkersOnInterval = markers.allIds
      .map((id, index) => (first <= index && index <= last ? index : null))
      .filter(item => item !== null);
    return allSelectedIndexMarkers.length === allIndexMarkersOnInterval.length;
  };

  const renderPrice = () => {
    const price = formatAmount(getCurrentPrice());
    return price;
  };
  const renderAdditionalCost = () => {
    if (Number(getCurrentPrice()) > account.amount) {
      return `${getCurrentPrice() - account.amount}`;
    }
    return null;
  };

  const renderButton = () => {
    if (getError() === 'service_consume_schedule_time_is_unavailable') {
      return '';
    }
    return renderPrice();
  };
  const handleInputChange = evt => {
    const { name, value } = evt.target;
    updateFilledState(name, value);
    updateFormData(name, value);
  };

  const updateFilledState = (name, inputValue) => {
    setFilled(prevState => ({
      ...prevState,
      [name]: inputValue !== ''
    }));
  };

  const handleFocus = fieldName => {
    setIsFocused(prevState => ({
      ...prevState,
      [fieldName]: true
    }));
  };

  const handleBlur = fieldName => {
    setIsFocused(prevState => ({
      ...prevState,
      [fieldName]: false
    }));
  };

  const updateFormData = (name, inputValue) => {
    setFormData(prevState => ({
      ...prevState,
      [name]: inputValue
    }));
  };

  const handleEraseValue = fieldName => {
    setFormData(prevState => ({
      ...prevState,
      [fieldName]: ''
    }));
  };

  const isActiveTimeLine = markers => {
    return markers.allIds.some(id => markers.byId[id].status === 'current');
  };
  useEffect(() => {
    const data = [];
    for (const key in rooms.byId) {
      if (rooms.byId.hasOwnProperty(key)) {
        const child = rooms.byId[key];
        data.push(child);
      }
    }
    setRoomsData(data);
    if (location) {
      switch (location.id) {
        case 2:
          if (roomName === 'M01') {
            setLocalImages([neoGeo1, neoGeo2]);
          } else if (roomName === 'M02') {
            setLocalImages([neoGeo3, neoGeo4]);
          } else {
            setLocalImages([neoGeo1, neoGeo2, neoGeo3, neoGeo4, neoGeo5, neoGeo6]);
          }
          break;
        case 4:
          if (roomName === 'М403') {
            setLocalImages([park1, park2]);
          } else if (roomName === 'М404') {
            setLocalImages([park3, park4]);
          }
          if (roomName === 'M607') {
            setLocalImages([park1, park2]);
          }
          if (roomName === 'M608') {
            setLocalImages([park3, park4]);
          } else {
            setLocalImages([park1, park2, park3, park4]);
          }
          break;
        case 5:
          if (roomName === 'Конференц-зал') {
            setLocalImages([fok1, fok2, fok3]);
          } else {
            setLocalImages([
              tulskaya1,
              tulskaya2,
              tulskaya3,
              tulskaya4,
              tulskaya5,
              tulskaya6,
              tulskaya7,
              tulskaya8,
              tulskaya9,
              tulskaya10
            ]);
          }
          break;
        case 7:
          if (roomName === 'M651') {
            setLocalImages([tulskaya2, tulskaya4]);
          } else {
            setLocalImages([
              tulskaya1,
              tulskaya2,
              tulskaya3,
              tulskaya4,
              tulskaya5,
              tulskaya6,
              tulskaya7,
              tulskaya8,
              tulskaya9,
              tulskaya10
            ]);
          }
          break;
        default:
          setLocalImages([background1, background2, background3, background4]);
      }
    }
    const allIndexMarkers = markers.allIds
      .map((id, index) => (markers.byId[id].status === null ? index : null))
      .filter(item => item !== null);
    const filteredTimeSlots = timeSlots.filter(slot => allIndexMarkers.includes(slot.key));
    setTimeSlots(filteredTimeSlots);
  }, []);
  useEffect(() => {
    const activeTimeSlot = timeSlots.find(slot => slot.checked === true);
    setActiveChip(activeTimeSlot);
  }, [timeSlots]);

  const handleMouseEnter = sliderId => {
    setActiveSlider(sliderId);
  };

  const handleMouseLeave = () => {
    setActiveSlider(null);
  };
  const displayPriceInfo = roomsData => {
    return (
      <MeetingRoomSubtitleWrapper>
        {roomsData.map(
          room =>
            room.service.name === roomName && (
              <MeetingRoomSubtitle>
                {(() => {
                  const filteredPrices = room.service.servicePrices.filter(servicePrice => {
                    return servicePrice.is_active === 1 && servicePrice.cnt_type_id === 3;
                  });

                  if (filteredPrices.length > 0) {
                    return customer.is_resident
                      ? filteredPrices.find(priceInfo => priceInfo.resident_only === 1).price
                      : filteredPrices.find(priceInfo => priceInfo.resident_only === 0).price;
                  } else {
                    return 'Нет данных';
                  }
                })()}
                ₽/ч
              </MeetingRoomSubtitle>
            )
        )}
        {roomsData.map(
          room =>
            room.service.name === roomName && (
              <MeetingRoomSubtitle>
                {(() => {
                  const filteredPrices = room.service.servicePrices.filter(servicePrice => {
                    return servicePrice.is_active === 1 && servicePrice.cnt_type_id === 4;
                  });

                  if (filteredPrices.length > 0) {
                    return customer.is_resident
                      ? filteredPrices.find(priceInfo => priceInfo.resident_only === 1).price
                      : filteredPrices.find(priceInfo => priceInfo.resident_only === 0).price;
                  } else {
                    return 'Нет данных';
                  }
                })()}
                ₽/день
              </MeetingRoomSubtitle>
            )
        )}
      </MeetingRoomSubtitleWrapper>
    );
  };

  return (
    <>
      <MeetingRoomWrapper>
        <MeetingRoomSliderWrapper onMouseEnter={() => handleMouseEnter(roomId)} onMouseLeave={handleMouseLeave}>
          <PictureSlider
            key={key}
            images={localImages}
            useManualControls={false}
            handleSlideChange={handleSlideChange}
            galleryIndex={activeSlide}
          />
          {isLaptop && !isDesktop && (
            <MeetingRoomTariffInfo>
              <MeetingTariffTitle>Стоимость аренды</MeetingTariffTitle>
              {displayPriceInfo(roomsData)}
            </MeetingRoomTariffInfo>
          )}
        </MeetingRoomSliderWrapper>
        <InfoWrapper order={!isDesktop ? 'column' : 'column-reverse'}>
          <div>
            <MeetingRoomMainInfo>
              <MeetingRoomTitle>
                {roomName.includes('Ивент') || roomName.includes('Конференц-зал')
                  ? roomName
                  : 'Переговорная ' + roomName}
              </MeetingRoomTitle>
              <MeetingRoomSubtitle>{location.t_name}</MeetingRoomSubtitle>
              {isTablet && !isDesktop && !isLaptop && (
                <MeetingRoomTariffInfo>
                  <MeetingTariffTitle>Стоимость аренды</MeetingTariffTitle>
                  {displayPriceInfo(roomsData)}
                </MeetingRoomTariffInfo>
              )}
              {description && (
                <CustomList
                  view={!isDesktop ? 'horizontal' : 'vertical'}
                  fontSize={'14px'}
                  size={'small'}
                  type='meeting'
                  propItems={description.split(';')}
                />
              )}
              {!isDesktop && (
                <TimeSlotsWrapper>
                  <TimeSlotsTitle>Выберите слот на час</TimeSlotsTitle>
                  <ChipList type={'time'}>
                    {markers.allIds.slice(0, 12).map(id => (
                      <MeetingRoomChip
                        key={id}
                        dateTimeObjects={dateTimeObjects.byId}
                        marker={markers.byId[id]}
                        timeMarker={dateTimeObjects.byId[id]}
                        changeMarker={changeMarker}
                        resetSelectedAll={resetSelectedAll}
                        changeIsMouseDown={changeIsMouseDown}
                        isMouseDown={isMouseDown}
                        setOverflowStatus={setOverflowStatus}
                        isCorrectedSelected={isCorrectedSelected}
                        setSelectedReservation={setSelectedReservation}
                      />
                    ))}
                  </ChipList>
                </TimeSlotsWrapper>
              )}
            </MeetingRoomMainInfo>
            {!isLaptop ||
              (isDesktop && (
                <MeetingRoomTariffInfo>
                  <MeetingTariffTitle>Стоимость аренды</MeetingTariffTitle>
                  {displayPriceInfo(roomsData)}
                </MeetingRoomTariffInfo>
              ))}
          </div>
          <TariffButtonWrapper>
            <FormControl fullWidth={true}>
              <StepButton
                handleAction={() => setOpenDialog(prevState => ({ ...prevState, booking: true }))}
                type={'outlined'}
                title={'Посмотреть доступное время'}
                spacing={'0'}
              />
            </FormControl>
          </TariffButtonWrapper>
        </InfoWrapper>
        {isLaptop && (
          <MeetingRoomTable>
            {isDesktop && (
              <TimeSlotsWrapper>
                <TimeSlotsTitle>Выберите слот на час</TimeSlotsTitle>
                <ChipList type={'time'}>
                  {markers.allIds.slice(0, 12).map(id => (
                    <MeetingRoomChip
                      key={id}
                      dateTimeObjects={dateTimeObjects.byId}
                      marker={markers.byId[id]}
                      timeMarker={dateTimeObjects.byId[id]}
                      changeMarker={changeMarker}
                      resetSelectedAll={resetSelectedAll}
                      changeIsMouseDown={changeIsMouseDown}
                      isMouseDown={isMouseDown}
                      setOverflowStatus={setOverflowStatus}
                      isCorrectedSelected={isCorrectedSelected}
                      setSelectedReservation={setSelectedReservation}
                    />
                  ))}
                </ChipList>
              </TimeSlotsWrapper>
            )}
            <TimeSlotsTitle>или укажите период для брони</TimeSlotsTitle>
            <MeetingRoomTimeRow>
              <table>
                <thead className='hours-picker__table-head'>
                  <tr className='hours-picker__row'>
                    {dateTimeObjects.allIds.map(id => {
                      return (
                        <HoursPickerTableHead key={id} date={dateFrom.date()} timeMarker={dateTimeObjects.byId[id]} />
                      );
                    })}
                  </tr>
                </thead>
                <tr className='hours-picker__row'>
                  <td />
                  {markers.allIds.slice(0, 12).map(id => (
                    <MeetingRoomInfoCell
                      key={id}
                      marker={markers.byId[id]}
                      markerList={markers.byId}
                      changeMarker={changeMarker}
                      resetSelectedAll={resetSelectedAll}
                      changeIsMouseDown={changeIsMouseDown}
                      isMouseDown={isMouseDown}
                      setOverflowStatus={setOverflowStatus}
                      isCorrectedSelected={isCorrectedSelected}
                      setSelectedReservation={setSelectedReservation}
                    />
                  ))}
                </tr>
              </table>
            </MeetingRoomTimeRow>
            <MeetingRoomBookingBlock>
              <div
                className={`room-reservation__reservation-result ${
                  isSelected() ? '' : 'room-reservation__reservation-result_empty'
                }`}
              >
                {(isSelected() && isActiveTimeLine(markers)) || activeChip ? (
                  <MeetingRoomPaymentInfo>
                    <DateTimeWrapper>
                      {dateTime && dateTime.from && (
                        <DateBlock>
                          <p>Бронь</p>
                          <p>{dateTime.from.format('D MMMM')}</p>
                        </DateBlock>
                      )}
                      <TimeWrapper>
                        <TimeBlock>
                          <FormControl fullWidth={true}>
                            <AddressFieldBlock>
                              <CustomTextField
                                error={''}
                                type='text'
                                disabled={true}
                                hideAdornment={true}
                                name='timeStart'
                                id='outlined-helperText'
                                label='с'
                                variant='filled'
                                value={formData.timeStart}
                                onFocus={() => handleFocus('timeStart')}
                                onBlur={() => handleBlur('timeStart')}
                                onChange={handleInputChange}
                                helperText={''}
                                endAdornmentProps={{
                                  filled: filled.timeStart,
                                  isFocused: isFocused.timeStart,
                                  handleEraseValue: name => handleEraseValue(name)
                                }}
                                autoFocus={false}
                              />
                            </AddressFieldBlock>
                          </FormControl>
                        </TimeBlock>
                        <p>—</p>
                        <TimeBlock>
                          <FormControl fullWidth={true}>
                            <AddressFieldBlock>
                              <CustomTextField
                                error={''}
                                disabled={true}
                                type='text'
                                name='timeEnd'
                                id='outlined-helperText'
                                label='до'
                                hideAdornment={true}
                                variant='filled'
                                value={formData.timeEnd}
                                onFocus={() => handleFocus('timeEnd')}
                                onBlur={() => handleBlur('timeEnd')}
                                onChange={handleInputChange}
                                helperText={''}
                                endAdornmentProps={{
                                  filled: filled.timeEnd,
                                  isFocused: isFocused.timeEnd,
                                  handleEraseValue: name => handleEraseValue(name)
                                }}
                                autoFocus={false}
                              />
                            </AddressFieldBlock>
                          </FormControl>
                        </TimeBlock>
                      </TimeWrapper>
                    </DateTimeWrapper>
                    <p className='room-reservation__book-info'>
                      <Hints
                        hints={['Вы можете выбрать не более 12 часов']}
                        hintText='Для того чтобы выбрать временной интервал - выделите необходимое время.'
                      />
                    </p>
                    <ReactTooltip />
                  </MeetingRoomPaymentInfo>
                ) : (
                  <p className='room-reservation__book-info'>
                    <Hints
                      hints={['Вы можете выбрать не более 12 часов']}
                      hintText='Для того чтобы выбрать временной интервал - выделите необходимое время.'
                    />
                  </p>
                )}
              </div>
              <PriceButtonWrapper>
                {isSelected() && isActiveTimeLine(markers) && (
                  <>
                    <MeetingRoomPriceInfo spacing={'24px'}>
                      <p>К оплате</p>
                      <p>{renderButton()}₽</p>
                    </MeetingRoomPriceInfo>
                    {renderAdditionalCost() && locationFreeHours === 0 && (
                      <MeetingRoomPriceInfo>
                        <p>Нужно внести</p>
                        <p>{renderAdditionalCost()}₽</p>
                      </MeetingRoomPriceInfo>
                    )}
                  </>
                )}
                {roomsData.map(
                  room =>
                    room.service.name === roomName && (
                      <MeetingRoomButtonWrapper>
                        <StepButton
                          disabled={!isSelected() || !isActiveTimeLine(markers) || room.service.id !== pickedRoom.id}
                          handleAction={() => {
                            isSelected() && renderAdditionalCost() && locationFreeHours === 0
                              ? (window.location.href = '/payments')
                              : reserveRoom();
                          }}
                          type={'filled'}
                          iconColor={'white'}
                          filledType={'red'}
                          title={
                            isSelected() && renderAdditionalCost() && locationFreeHours === 0
                              ? 'Пополнить'
                              : 'Забронировать'
                          }
                          spacing={'0'}
                          iconType={'arrow'}
                        />
                      </MeetingRoomButtonWrapper>
                    )
                )}
              </PriceButtonWrapper>
            </MeetingRoomBookingBlock>
          </MeetingRoomTable>
        )}
      </MeetingRoomWrapper>
      {openDialog.booking && (
        <MeetingRoomDialog
          dialogType='meetingRoomBooking'
          dialogData={props}
          handleSubmit={() => {
            setOpenDialog(prevState => ({ ...prevState, booking: false }));
          }}
          handleClose={() => {
            setOpenDialog(prevState => ({ ...prevState, booking: false }));
          }}
        />
      )}
    </>
  );
};

MeetingRoomInfo.propTypes = {
  roomName: PropTypes.string.isRequired,
  description: PropTypes.string,
  markers: PropTypes.object.isRequired,
  changeMarker: PropTypes.func.isRequired,
  resetSelectedAll: PropTypes.func.isRequired,
  setSelectedReservation: PropTypes.func.isRequired,
  setOverflowStatus: PropTypes.func.isRequired,
  dateTimeObjects: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
  galleryIndex: PropTypes.number.isRequired,
  handleSlideChange: PropTypes.func.isRequired,
  images: PropTypes.array.isRequired,
  dateTime: PropTypes.object.isRequired,
  isSelected: PropTypes.func.isRequired,
  getCurrentPrice: PropTypes.func.isRequired,
  getError: PropTypes.func.isRequired,
  room: PropTypes.object.isRequired
};

// const arePropsEqual = (prevProps, nextProps) => {
//   return isEqual(prevProps, nextProps);
// };

export default MeetingRoomInfo;
