import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { IconComponent } from './Field/IconComponent';
import { CustomTextField } from './Field/CustomTextField';
import { FormControl } from '@mui/material';
import { Success } from '../icons/core/Success.jsx';
import PaymentInfo from '../../../widgets/Payment/PaymentInfo';
import { CustomSelectField } from './Select';
import { StepButton } from './StepButton';
import { TabsComponent } from './Tabs/Tabs';
import { InfoAlert } from './Profile/InfoAlert';
import dayjs from 'dayjs';
import BookingWidget, { BookingWrapper } from '../../../widgets/BookingWidgets/BookingWidget';
import { customerSelector } from '../../../store/selectors/DefaultSelectors';
import { ErrorIcon } from '../icons/core/Error';
import { colors, fontFamilyBase } from '../constants/styleConstants.js';
import DashboardActions from '../../../widgets/DashboardWidgets/DashboardActions.jsx';
import { ArrowBack } from '../icons/core/ArrowBack.jsx';
import { MeetingRoomWidget } from '../../../widgets/BookingWidgets/MeetingRooms/MeetingRoomWidget';
import { useSelector } from 'react-redux';
import Portal, { createContainer } from '../../../hocs/Portal';
import GuestPassWidget from '../../../widgets/Services/GuestPass/GuestPassWidget';
import ParkingWidget from '../../../widgets/Services/Parking/ParkingWidget';
import ServiceDetailsWidget from '../../../widgets/Services/ServiceDetailsWidget';
import WorkplaceInfo from '../../../widgets/BookingWidgets/BookingInfo/WorkplaceInfo';
import { TabsWrapper } from '../../../entities/Profile/PrefillProfile/model/PrefillProfileComponent';
import EmployeeWidget from '../../../widgets/ProfileWidgets/Employees/EmployeeWidget';
import TMCWidget from '../../../widgets/Services/TMC/TMCWidget';
import PrintFileWidget from '../../../widgets/Services/Print/PrintFileWidget';
import ChangeCredentialsWidget from '../../../widgets/ProfileWidgets/Employees/ChangeCredentialsWidget';

export const DialogWrapper = styled.div`
  background: #383c4080;
  position: fixed;
  width: 100vw;
  height: 100vh;
  z-index: 20 !important;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;

  @media (min-width: 320px) {
    align-items: flex-end;
    z-index: 20 !important;
  }

  @media (min-width: 768px) {
    align-content: center;
    align-items: center;
  }
`;

export const DialogContent = styled.div`
  padding: ${props => (props.type === 'confirmation' ? '0' : '24px 16px')};
  z-index: 50 !important;
  transform: translateZ(100px);
  height: fit-content;
  position: absolute;
  overflow-y: auto;
  background-color: ${props => (props.type === 'confirmation' ? '#F4F4F4' : 'white')};
  color: #000000;
  border: none;
  border-radius: 16px;

  @media (min-width: 320px) {
    justify-self: flex-end;
    width: 100vw;
    max-height: ${props => (props.android ? '90vh' : '80vh')};
    padding: ${props => (props.type === 'confirmation' ? '0' : '0 16px 30px 16px')};
    bottom: ${props => (props.safari ? '80px' : props.android ? '60px' : props.yandex ? '80px' : '50px')};
    border-bottom-left-radius: ${props => (props.safari ? '0' : '16px')};
    border-bottom-right-radius: ${props => (props.safari ? '0' : '16px')};
  }

  @media (min-width: 768px) {
    height: fit-content;
    max-height: 90vh;
    justify-self: center;
    align-self: center;
    width: 464px;
    top: 20px;
    bottom: 10px;
    border-bottom-left-radius: 16px;
    border-bottom-right-radius: 16px;
    padding: ${props => (props.type === 'confirmation' ? '0' : '0 24px 16px 24px')};
  }
`;

export const TopBlock = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: ${props => (props.direction === 'flex-end' ? '16px' : '0')};
  margin-bottom: ${props => props.spacing || 0};
  justify-content: ${props => props.direction};
  gap: ${props => (props.gap ? props.gap : '')};
`;

export const Confirmed = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-bottom: 16px;
  margin-top: 16px;
`;

export const Title = styled.p`
  font-weight: 500;
  font-size: 22px;
  line-height: 24px;
  font-family: ${fontFamilyBase};
  color: ${colors.workkiDarkMain};
`;

const Description = styled.p`
  margin: ${props => (props.type === 'important' ? '6px 0 12px 0' : '6px 0 0 0')};
  padding: 0;
  font-family: ${fontFamilyBase};
  font-weight: ${props => (props.type === 'important' ? 500 : 400)};
  font-size: ${props => (props.type === 'important' ? '16px' : '14px')};
  line-height: 18px;
  text-align: ${props => (props.textAlign ? props.textAlign : props.type === 'important' ? 'start' : 'center')};
  color: ${props =>
    props.type === 'important' || props.colorOverride ? colors.workkiDarkFill : colors.workkiDarkSecondary};

  &:first-of-type {
    margin-top: 0;
  }

  & > span {
    margin: 0;
    padding: 0;
    font-size: 14px;
    font-weight: ${props => (props.type === 'importantCaption' ? 600 : 500)};
    line-height: 18px;
  }
`;
export const ButtonWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin: 16px;
`;
export const PrintCaption = styled.p`
  font-weight: 400;
  font-size: 14px;
  margin: -16px 0 16px 0;
  text-align: center;
  & > span {
    font-weight: 600;
  }
`;
export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  background-color: white;
  text-align: center;
  padding: 24px 16px;
  border-radius: 16px;
  margin-bottom: 8px;
  & > p {
    margin: 0;
  }
`;

export const Dialog = ({
  title,
  fields,
  errors,
  formData,
  handleInputChange,
  handleFocus,
  handleBlur,
  handleEraseValue,
  handleSubmit,
  isPending,
  filled,
  isFocused,
  handleClose,
  buttonLabel,
  requestSentMessage,
  requestSentDescription,
  requestSent,
  disableButton,
  dialogType,
  dialogData,
  saveDocument,
  destinationOptions,
  handleSelectChange,
  inputRef,
  tabValue,
  tabs,
  handleTabChange,
  //Временные данные
  alert,
  serviceProps,
  workplaces,
  unfixedWorkplaces,
  currentLocationValue,
  currentSubLocationValue,
  handlePrevStep,
  handleChangeInfoTypeState
}) => {
  const DIALOG_CONTAINER_ID = 'dialog';
  const customer = useSelector(customerSelector);
  const [isMount, setIsMount] = useState(false);
  const [safari, setSafari] = useState(false);
  const [yandex, setYandex] = useState(false);
  const [android, setAndroid] = useState(false);
  const [scrollPosition, setScrollPosition] = useState(0);
  const getMobileOS = () => {
    const userAgent = navigator.userAgent || navigator.vendor || window.opera;

    if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
      return 'IOS';
    }

    if (/android/i.test(userAgent)) {
      return 'Android';
    }

    return 'unknown';
  };

  useEffect(() => {
    const os = getMobileOS();
    if (os === 'Android') {
      setAndroid(true);
    }
    const isSafari = () => {
      const ua = navigator.userAgent.toLowerCase();
      return /safari/.test(ua) && !/chrome/.test(ua);
    };
    const isYandex = /yabrowser/i.test(navigator.userAgent.toLowerCase());
    setYandex(isYandex);
    setSafari(isSafari);
    setIsMount(true);
    createContainer({ id: DIALOG_CONTAINER_ID });
  }, []);
  //TODO убрать инфу для DatePicker
  const [pickedDate, setPickedDate] = useState(dayjs());
  const displayHelperText = field => {
    if (field.complexValueKey) {
      return errors[field.type][field.valueKey] ? errors[field.type][field.valueKey] : '';
    } else {
      return errors[field.valueKey] ? errors[field.valueKey] : '';
    }
  };
  const displayWorkplaceTitle = (name, tariffName) => {
    if (tariffName.includes('Коворкинг')) {
      return 'Фиксированное рабочее место' + ' ' + name;
    } else return name + ' ' + tariffName;
  };
  const displayMeetingRoomTitle = name => {
    if (name.includes('Ивент-пространство')) {
      return 'Ивент-пространство успешно оплачено и забронировано';
    } else if (name.includes('Конференц-зал')) {
      return 'Конференц-зал успешно оплачен и забронирован';
    } else return 'Переговорная' + ' ' + name + ' успешно оплачена и забронирована';
  };

  const getDescriptionItems = (description, alignCenter) => {
    if (!Array.isArray(description)) description = [description];
    return description.map((item, index) => {
      if (typeof item === 'object') {
        return (
          <Description key={index} textAlign={alignCenter}>
            {item.text} {!!item.price && <span>{item.price}.</span>}
          </Description>
        );
      }
      return (
        <Description key={index} textAlign={alignCenter}>
          {item}
        </Description>
      );
    });
  };

  const dialogTypes = {
    default: () => (
      <div>
        <TopBlock direction={'space-between'}>
          <Title>{title}</Title>
          <IconComponent
            type='close'
            spacing={'0'}
            handleAction={handleClose}
            defaultColor={'#24272A'}
            hoverColor={'#383c40'}
          />
        </TopBlock>
        {fields
          .filter(field => field.view === 'field')
          .map((field, index) => (
            <CustomTextField
              key={index}
              error={field.complexValueKey ? errors[field.type][field.valueKey] : errors[field.valueKey]}
              type={field.type}
              name={field.name}
              id='outlined-helperText'
              label={field.label}
              variant='filled'
              value={field.complexValueKey ? formData[field.type][field.valueKey] : formData[field.valueKey]}
              onFocus={() => handleFocus(field.name, field.context)}
              onBlur={() => handleBlur(field.name, field.context)}
              onChange={handleInputChange}
              disabled={isPending}
              helperText={displayHelperText(field)}
              endAdornmentProps={{
                filled: field.complexValueKey ? filled[field.type][field.valueKey] : filled[field.valueKey],
                isFocused: field.complexValueKey ? isFocused[field.type][field.valueKey] : isFocused[field.valueKey],
                handleEraseValue: name => handleEraseValue(name, field.context)
              }}
              autoFocus={field.autoFocus}
              placeholder={field.placeholder}
              startAdornment={field.startAdornment}
            />
          ))}
        <FormControl fullWidth={true}>
          <StepButton
            isabled={disableButton}
            filledType={'black'}
            type={'filled'}
            title={buttonLabel}
            handleAction={event => handleSubmit(event)}
          />
        </FormControl>
      </div>
    ),
    confirmed: () => (
      <Confirmed>
        <Success defaultColor={'#00A676'} spacing={'0'} />
        <Title>{requestSentMessage}</Title>
        <Description>{getDescriptionItems(requestSentDescription)}</Description>
        <FormControl fullWidth={true} style={{ marginTop: '32px' }}>
          <StepButton filledType={'black'} type={'filled'} title={'Спасибо'} handleAction={() => handleClose()} />
        </FormControl>
      </Confirmed>
    ),
    operationInfo: () => (
      <>
        <TopBlock direction={'flex-end'}>
          <IconComponent
            spacing={'0'}
            type='close'
            handleAction={handleClose}
            defaultColor={'#24272A'}
            hoverColor={'#383c40'}
          />
        </TopBlock>
        <PaymentInfo saveDocument={saveDocument} operationData={dialogData} handleClose={handleClose} />
      </>
    ),
    generalInfo: () => (
      <>
        <TopBlock direction={'space-between'}>
          <Title>{title}</Title>
          <IconComponent
            type='close'
            spacing={'0'}
            handleAction={handleSubmit}
            defaultColor={'#24272A'}
            hoverColor={'#383c40'}
          />
        </TopBlock>
        {alert && <Description type={'important'}>{alert}</Description>}
        {getDescriptionItems(dialogData, 'start')}
        <FormControl fullWidth={true} style={{ marginTop: '32px' }}>
          <StepButton type={'filled'} filledType={'black'} title={buttonLabel} handleAction={handleSubmit} />
        </FormControl>
      </>
    ),
    bookingInfo: () => (
      <>
        <TopBlock direction={'space-between'}>
          {dialogData && <Title>{displayWorkplaceTitle(dialogData.name, dialogData.tariff.name)} </Title>}
          <IconComponent
            spacing={'0'}
            type='close'
            handleAction={handleClose}
            defaultColor={'#24272A'}
            hoverColor={'#383c40'}
          />
        </TopBlock>
        <WorkplaceInfo unfixedWorkplaces={unfixedWorkplaces} dialogData={dialogData} handleSubmit={handleSubmit} />
      </>
    ),
    meetingRoomBooking: () => (
      <MeetingRoomWidget handleClose={handleClose} dialogData={dialogData} handleSubmit={handleSubmit} />
    ),
    meetingRoomSuccess: () => (
      <>
        <Wrapper>
          <Confirmed>
            <Success defaultColor={'#00A676'} spacing={'0'} />
            {dialogData.name && <Title>{displayMeetingRoomTitle(dialogData.name)}</Title>}
            {customer && <Description>Подтверждение бронирования отправлено на почту {customer.email}</Description>}
          </Confirmed>
        </Wrapper>
        <BookingWrapper>
          <Title>Также вы можете</Title>
          <DashboardActions />
        </BookingWrapper>
        <ButtonWrapper>
          <FormControl fullWidth={true}>
            <StepButton
              type={'filled'}
              filledType={'black'}
              title={'Готово'}
              disabled={disableButton}
              handleAction={handleSubmit}
            />
          </FormControl>
        </ButtonWrapper>
      </>
    ),
    bookingSuccess: () => (
      <>
        <Wrapper>
          <Confirmed>
            <Success defaultColor={'#00A676'} spacing={'0'} />
            {dialogData && <Title>Рабочее место {dialogData.name} успешно оплачено и забронировано</Title>}
            <Description>Подтверждение бронирования отправлено на почту {customer.email}</Description>
          </Confirmed>
        </Wrapper>
        <BookingWrapper>
          <Title>Также вы можете</Title>
          <DashboardActions />
        </BookingWrapper>
        <ButtonWrapper>
          <FormControl fullWidth={true}>
            <StepButton
              type={'filled'}
              filledType={'black'}
              title={buttonLabel}
              disabled={disableButton}
              handleAction={handleSubmit}
            />
          </FormControl>
        </ButtonWrapper>
      </>
    ),
    guestPassSuccess: () => (
      <>
        <Confirmed>
          <Success defaultColor={'#00A676'} spacing={'0'} />
          <Title>{title}</Title>
          <Description>Уже можно приходить, мы предупредили менеджеров.</Description>
          <Description>Напоминаем, что гостям запрещено находиться в коворкинге более двух часов.</Description>
        </Confirmed>
        <FormControl fullWidth={true}>
          <StepButton type={'filled'} filledType={'black'} title={buttonLabel} handleAction={handleClose} />
        </FormControl>
      </>
    ),
    commomServiceSuccess: () => (
      <>
        <Confirmed>
          <Success defaultColor={'#00A676'} spacing={'0'} />
          <Title>{title}</Title>
          <Description>Уже можно приходить, мы предупредили менеджеров.</Description>
        </Confirmed>
        <FormControl fullWidth={true}>
          <StepButton type={'filled'} filledType={'black'} title={buttonLabel} handleAction={handleClose} />
        </FormControl>
      </>
    ),
    bookingError: () => (
      <>
        <Confirmed>
          <ErrorIcon defaultColor={'#DB4344'} spacing={'0'} />
          <Title>{title}</Title>
          {dialogData && <Description>{dialogData}</Description>}
        </Confirmed>
        <FormControl fullWidth={true}>
          <StepButton
            type={'filled'}
            filledType={'black'}
            title={buttonLabel}
            disabled={disableButton}
            handleAction={handleSubmit}
          />
        </FormControl>
      </>
    ),
    bookingConfirmation: () => (
      <>
        <BookingWidget
          handleClose={handleClose}
          pickedDate={pickedDate}
          setPickedDate={value => setPickedDate(value)}
          handleTabChange={handleTabChange}
          dialogData={dialogData}
          unfixedWorkplaces={unfixedWorkplaces}
          currentLocationValue={currentLocationValue}
          currentSubLocationValue={currentSubLocationValue}
          handleSubmit={handleSubmit}
          handlePrevStep={handlePrevStep}
        />
      </>
    ),
    balance: () => (
      <>
        <TopBlock direction={'space-between'}>
          <Title>{title}</Title>
          <IconComponent
            type='close'
            spacing={'0'}
            handleAction={handleClose}
            defaultColor={'#24272A'}
            hoverColor={'#383c40'}
          />
        </TopBlock>
        {customer && customer.is_company && (
          <>
            <TabsWrapper>
              <TabsComponent
                tabsContainerStyle={{ width: '100%', height: '48px' }}
                tabStyle={{ height: '40px' }}
                tabValue={tabValue}
                handleTabChange={handleTabChange}
                tabs={tabs}
              />
            </TabsWrapper>
            <InfoAlert
              title={'Используйте корпоративную банковскую карту. Для пополнения счета иной картой'}
              subTitle={'необходимо письмо об оплате за другую организацию.'}
              type={'info'}
            />
          </>
        )}
        {fields
          .filter(field => field.view === 'select')
          .map((field, index) => (
            <CustomSelectField
              isFocused={isFocused}
              name={field.name}
              id='custom-select'
              label={field.label}
              variant='filled'
              value={field.complexValueKey ? formData[field.type][field.valueKey] : formData[field.valueKey]}
              onFocus={() => handleFocus(field.name, field.context)}
              onBlur={() => handleBlur(field.name, field.context)}
              onChange={handleSelectChange}
              options={destinationOptions}
            />
          ))}
        {fields
          .filter(field => field.view === 'field')
          .map((field, index) => (
            <CustomTextField
              key={index}
              error={field.complexValueKey ? errors[field.type][field.valueKey] : errors[field.valueKey]}
              type={field.type}
              name={field.name}
              id='outlined-helperText'
              label={field.label}
              variant='filled'
              value={field.complexValueKey ? formData[field.type][field.valueKey] : formData[field.valueKey]}
              onFocus={() => handleFocus(field.name, field.context)}
              onBlur={() => handleBlur(field.name, field.context)}
              onChange={handleInputChange}
              disabled={isPending}
              helperText={displayHelperText(field)}
              inputRef={inputRef}
              endAdornmentProps={{
                filled: field.complexValueKey ? filled[field.type][field.valueKey] : filled[field.valueKey],
                isFocused: field.complexValueKey ? isFocused[field.type][field.valueKey] : isFocused[field.valueKey],
                handleEraseValue: name => handleEraseValue(name, field.context)
              }}
              autoFocus={field.autoFocus}
              placeholder={field.placeholder}
              startAdornment={field.startAdornment}
            />
          ))}
        <FormControl fullWidth={true}>
          <StepButton
            type={'filled'}
            filledType={'black'}
            title={buttonLabel}
            disabled={disableButton}
            handleAction={handleSubmit}
          />
        </FormControl>
      </>
    ),
    dashboardActions: () => (
      <>
        <TopBlock direction={'space-between'}>
          <Title>{title}</Title>
          <IconComponent
            type='close'
            handleAction={handleClose}
            defaultColor={'#24272A'}
            hoverColor={'#383c40'}
            spacing='0'
          />
        </TopBlock>
        <DashboardActions handleChangeInfoTypeState={handleChangeInfoTypeState} />
      </>
    ),
    bookingCancel: () => (
      <>
        <TopBlock gap='12px'>
          <ArrowBack
            defaultColor={colors.workkiDarkMain}
            spacing='0'
            onClick={() => handleChangeInfoTypeState('dashboardActions')}
            width='24px'
          />
          <Title>{title}</Title>
        </TopBlock>
        {getDescriptionItems(dialogData)}
        <FormControl fullWidth={true} style={{ marginTop: '32px', gap: '12px' }}>
          <StepButton
            filledType={'white'}
            type={'filled'}
            title={'Да, хочу отменить'}
            handleAction={() => {
              // TODO подключить логику отправки заявки на отмену бронирования
              handleChangeInfoTypeState('confirmed');
            }}
            style={{ border: `1px solid ${colors.workkiDarkMain}` }}
            titleStyle={{ color: colors.workkiDarkMain }}
          />
          <StepButton
            filledType={'black'}
            type={'filled'}
            title={'Нет, не отменять'}
            handleAction={() => handleChangeInfoTypeState('dashboardActions')}
          />
        </FormControl>
      </>
    ),
    guestPass: () => (
      <>
        <TopBlock direction={'space-between'}>
          <Title>{title}</Title>
          <IconComponent
            type='close'
            handleAction={handleClose}
            defaultColor={'#24272A'}
            hoverColor={'#383c40'}
            spacing='0'
          />
        </TopBlock>
        <GuestPassWidget guestProps={serviceProps} workplaces={workplaces} handleClose={handleClose} />
      </>
    ),
    parking: () => (
      <>
        <TopBlock direction={'space-between'}>
          <Title>{title}</Title>
          <IconComponent
            type='close'
            handleAction={handleClose}
            defaultColor={'#24272A'}
            hoverColor={'#383c40'}
            spacing='0'
          />
        </TopBlock>
        <ParkingWidget
          scrollPosition={scrollPosition}
          parkingProps={serviceProps}
          workplaces={workplaces}
          handleClose={handleClose}
        />
      </>
    ),
    tmc: () => (
      <>
        <TopBlock direction={'space-between'}>
          <Title>{title}</Title>
          <IconComponent
            type='close'
            handleAction={handleClose}
            defaultColor={'#24272A'}
            hoverColor={'#383c40'}
            spacing='0'
          />
        </TopBlock>
        <TMCWidget
          scrollPosition={scrollPosition}
          parkingProps={serviceProps}
          workplaces={workplaces}
          handleClose={handleClose}
        />
      </>
    ),
    support: () => (
      <>
        <TopBlock direction={'space-between'}>
          <Title>{title}</Title>
          <IconComponent
            type='close'
            handleAction={handleClose}
            defaultColor={'#24272A'}
            hoverColor={'#383c40'}
            spacing='0'
          />
        </TopBlock>
      </>
    ),
    printFile: () => (
      <>
        <TopBlock direction={'space-between'}>
          <Title>{title}</Title>
          <IconComponent
            type='close'
            handleAction={handleClose}
            defaultColor={'#24272A'}
            hoverColor={'#383c40'}
            spacing='0'
          />
        </TopBlock>
        <PrintFileWidget dialogData={dialogData} handleClose={handleClose} handleSubmit={handleSubmit} />
      </>
    ),
    printSuccess: () => (
      <>
        <Confirmed>
          <Success defaultColor={'#00A676'} spacing={'0'} />
          <Title>{title}</Title>
          <PrintCaption>
            Подойдите к любому принтеру и введите ID <span>{dialogData.id}</span> и пинкод{' '}
            <span>{customer.printer && customer.printer.pin}</span>
          </PrintCaption>
        </Confirmed>
        <FormControl fullWidth={true}>
          <StepButton type={'filled'} filledType={'black'} title={buttonLabel} handleAction={handleClose} />
        </FormControl>
      </>
    ),
    serviceDetails: () => (
      <>
        <TopBlock direction={'space-between'}>
          <Title>{title}</Title>
          <IconComponent
            type='close'
            handleAction={handleClose}
            defaultColor={'#24272A'}
            hoverColor={'#383c40'}
            spacing='0'
          />
        </TopBlock>
        <ServiceDetailsWidget customer={customer} servicesInfo={dialogData} />
      </>
    ),
    addEmployee: () => (
      <>
        <TopBlock direction={'space-between'}>
          <Title>{title}</Title>
          <IconComponent
            type='close'
            handleAction={handleClose}
            defaultColor={'#24272A'}
            hoverColor={'#383c40'}
            spacing='0'
          />
        </TopBlock>
        <EmployeeWidget type={dialogType} handleClose={handleClose} />
      </>
    ),
    editEmployee: () => (
      <>
        <TopBlock direction={'space-between'}>
          <Title>{title}</Title>
          <IconComponent
            type='close'
            handleAction={handleClose}
            defaultColor={'#24272A'}
            hoverColor={'#383c40'}
            spacing='0'
          />
        </TopBlock>
        <EmployeeWidget
          type={dialogType}
          handleClose={handleClose}
          dialogData={dialogData}
          handleSubmit={handleSubmit}
        />
      </>
    ),
    removeEmployee: () => (
      <>
        <TopBlock direction={'space-between'}>
          <Title>{title}</Title>
          <IconComponent
            type='close'
            handleAction={handleClose}
            defaultColor={'#24272A'}
            hoverColor={'#383c40'}
            spacing='0'
          />
        </TopBlock>
        <Description textAlign={'flex-start'} colorOverride={colors.workkiDarkFill} type={'importantCaption'}>
          Личный кабинет сотрудника <span>{dialogData && dialogData.name}</span> будет деактивирован без возможности
          восстановления
        </Description>
        <FormControl fullWidth={true} style={{ gap: '12px', margin: '16px 0 8px 0' }}>
          <StepButton type={'outlined'} title={'Да, удалить'} handleAction={() => handleSubmit()} />
          <StepButton
            type={'filled'}
            filledType={'black'}
            title={'Нет, не удалять'}
            handleAction={() => handleClose()}
          />
        </FormControl>
      </>
    ),
    changeCredentials: () => (
      <>
        <TopBlock direction={'space-between'}>
          <Title>{title}</Title>
          <IconComponent
            type='close'
            handleAction={handleClose}
            defaultColor={'#24272A'}
            hoverColor={'#383c40'}
            spacing='0'
          />
        </TopBlock>
        <ChangeCredentialsWidget />
      </>
    )
  };

  const renderDialogContent = () => {
    if (requestSent && dialogType !== 'operationInfo') {
      return dialogTypes.confirmed();
    }
    return dialogTypes[dialogType] ? dialogTypes[dialogType]() : dialogTypes.default();
  };

  const handleScroll = e => {
    const { scrollTop, scrollHeight, clientHeight } = e.target;
    const position = Math.ceil((scrollTop / (scrollHeight - clientHeight)) * 100);
    setScrollPosition(position);
  };

  return (
    <>
      {isMount && (
        <Portal id={DIALOG_CONTAINER_ID}>
          <DialogWrapper onClick={handleClose}>
            <DialogContent
              onScroll={handleScroll}
              safari={safari}
              yandex={yandex}
              android={android}
              type={
                dialogType === 'bookingConfirmation' || dialogType === 'bookingSuccess' ? 'confirmation' : 'standard'
              }
              onClick={evt => evt.stopPropagation()}
            >
              {renderDialogContent()}
            </DialogContent>
          </DialogWrapper>
        </Portal>
      )}
    </>
  );
};
